<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2">
                                <v-select v-model="entityModel" :items="entityLists" default="" item-value="entity_id" item-text="entity_id" label="Entity" :disabled="true" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select 
                                    v-model="millModel" 
                                    :items="millLists" 
                                    default="" 
                                    item-value="mill_id" 
                                    item-text="mill_name" 
                                    label="Ship From" 
                                    clearable 
                                    dense 
                                    prepend-icon="mdi-factory" 
                                    :disabled="isMillDisabled" 
                                    @change="getExpedisi"
                                ></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="expModel" :items="expLists" default="" item-value="exp_id" item-text="exp_descr" label="Expedition" clearable dense prepend-icon="mdi-truck"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" dense label="Start Date" readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" dense label="End Date" readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-4">
                                <v-btn class="mt-1" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="['cardResult', isDetailDialogVisible ? 'col-lg-12' : 'col-lg-12']" transition="slide-x-reverse-transition">
                <div class="card">
                    <div class="card-body">
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" height="200px" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="d-flex w-25">
                                        <v-text-field 
                                            v-model="searchItem" 
                                            append-icon="mdi-magnify" 
                                            label="Search" 
                                            dense 
                                            single-line 
                                            hide-details>
                                        </v-text-field>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        small
                                        color="#005c37"
                                        class="py-5 text-white border-12 ml-auto"
                                    >
                                        <v-icon>mdi-file-excel</v-icon>
                                        <download-excel
                                            class="text"
                                            :fetch="exportExcelGetData"
                                            :fields="headersColumnGetData"
                                            :before-generate="startDownload"
                                            :before-finish="finishDownload"
                                            :name="`Export_CostRatio_KMB_${dateStartFormatted}-${dateEndFormatted}.xls`">
                                            Export Excel
                                        </download-excel>
                                    </v-btn>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.showDetails`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip @click="showItemDetails(item)" color="blue" link outlined v-on="on">Details</v-chip>
                                    </template>
                                    <span>Show Detail</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
            <transition name="slide-x-reverse-transition" mode="out-in">
                <div v-if="isDetailDialogVisible" class="col-lg-6 cardResult">
                    <div class="card">
                        <div class="card-body">
                            <v-data-table 
                            :headers="headersDetails" 
                            :items="itemDetailLists" 
                            class="elevation-1" 
                            page-count="5" 
                            :loading="loadingDatatableDetail" 
                            dense
                            height="400px" 
                            :items-per-page="itemDetailLists.length"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <h4>External Expedition</h4>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        small
                                        color="#005c37"
                                        class="py-5 text-white border-12 ml-auto"
                                    >
                                        <v-icon>mdi-file-excel</v-icon>
                                        <download-excel
                                            class="text"
                                            :fetch="exportExcelExternal"
                                            :fields="headersExternalColumn"
                                            :before-generate="startDownload"
                                            :before-finish="finishDownload"
                                            :name="`Export_CostRatio_External_KMB_${dateStartFormatted}-${dateEndFormatted}.xls`">
                                            Export Excel
                                        </download-excel>
                                    </v-btn>
                                    <button 
                                        type="button" 
                                        class="btn btn-danger" 
                                        @click="close"
                                    >
                                        Hide
                                    </button>
                                </v-toolbar>
                            </template>

                            <template v-slot:item="{ item }">
                                <tr :class="{ 'tr_choice_error': parseFloat(item.persen) > 2 }">
                                <td>{{ item.tr_id }}</td>
                                <td>{{ item.destination }}</td>
                                <td>{{ item.exp_descr }}</td>
                                <td>{{ item.driver_id }}</td>
                                <td>{{ item.vehicle_id }}</td>
                                <td>{{ item.JumlahSJ }}</td>
                                <td>{{ item.IDRBarang }}</td>
                                <td>{{ item.IDROngkir }}</td>
                                <td>{{ item.persen }}</td>
                                <td>{{ item.persenWgt }}</td>
                                </tr>
                            </template>
                            </v-data-table>
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="slide-x-reverse-transition" mode="out-in">
                <div v-if="isDetailDialogVisible" class="col-lg-6 cardResult">
                    <div class="card">
                        <div class="card-body">
                            <v-data-table 
                            :headers="headersInternalDetails" 
                            :items="itemKepuhDetailLists" 
                            class="elevation-1" 
                            page-count="5" 
                            :loading="loadingDatatableDetail" 
                            dense
                            height="400px" 
                            :items-per-page="itemKepuhDetailLists.length"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <h4>Internal Expedition</h4>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        small
                                        color="#005c37"
                                        class="py-5 text-white border-12 ml-auto"
                                    >
                                        <v-icon>mdi-file-excel</v-icon>
                                        <download-excel
                                            class="text"
                                            :fetch="exportExcelInternal"
                                            :fields="headersInternalColumn"
                                            :before-generate="startDownload"
                                            :before-finish="finishDownload"
                                            :name="`Export_CostRatio_Internal_KMB_${dateStartFormatted}-${dateEndFormatted}.xls`">
                                            Export Excel
                                        </download-excel>
                                    </v-btn>
                                    <button 
                                        type="button" 
                                        class="btn btn-danger" 
                                        @click="close"
                                    >
                                        Hide
                                    </button>
                                </v-toolbar>
                            </template>
                            <template v-slot:item="{ item }">
                                <tr :class="{ 'tr_choice_error': parseFloat(item.persen) > 2 }">
                                <td>{{ item.tr_id }}</td>
                                <td>{{ item.destination }}</td>
                                <td>{{ item.exp_descr }}</td>
                                <td>{{ item.driver_id }}</td>
                                <td>{{ item.vehicle_id }}</td>
                                <td>{{ item.JumlahSJ }}</td>
                                <td>{{ item.IDRBarang }}</td>
                                <td>{{ item.IDROngkir }}</td>
                                <td>{{ item.persen }}</td>
                                <td>{{ item.persenWgt }}</td>
                                </tr>
                            </template>
                            </v-data-table>
                        </div>
                    </div>
                </div>
            </transition>
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Summary</h5>
                        <div class="table-responsive">
                            <table class="styled-table">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Total Delivery Note :</td>
                                        <th>
                                            {{ sumDelivNote ? sumDelivNote : '-'}}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>Total Goods : </td>
                                        <th>
                                            IDR {{ sumTotalGoods ? sumTotalGoods : '-'}}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>Total Shipping Cost : </td>
                                        <th>
                                            IDR {{ sumShippingCost ? sumShippingCost : '-'}}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>Total Cost Ratio : </td>
                                        <th>
                                            {{ sumCostRatio ? sumCostRatio : '-'}} %
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>Total Cost Weight Ratio : </td>
                                        <th>
                                            {{ sumCostWeightRatio ? sumCostWeightRatio : '-'}} %
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>

import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'KMB',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Reports',
                disabled: false,
                href: '#',
                },
                {
                text: 'Cost Weight Ratio',
                disabled: true,
                href: '#',
                },
            ],
            entityModel: 'KMB',
            entityLists: [
                { entity_id: 'KMB' },
            ],
            millModel: '',
            expModel: '',
            millLists: [],
            expLists:[],
            dateStartModel: '',
            dateStartModal: false,
            dateEndModel: '',
            dateEndModal: false,
            isMillDisabled: false,
            itemLists: [],
            headers: [
                { text: 'SHIP. DATE', value: 'tglKirim', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'TOTAL DELIV. NOTE', value: 'JumlahSJ', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'GOODS(IDR)', value: 'IDRBarang', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SHIPPING COST(IDR)', value: 'IDROngkir', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'COST RATIO(%)', value: 'persen', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'MIN COST RATIO(%)', value: 'Minpersen', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'MAX COST RATIO(%)', value: 'Maxpersen', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WEIGHT RATIO(%)', value: 'persenWgt', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'MIN WEIGHT RATIO(%)', value: 'MinpersenWgt', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'MAX WEIGHT RATIO(%)', value: 'MaxpersenWgt', align: 'right', class: 'primary--text blue lighten-5' },
                { text: '', value: 'showDetails', align: 'center', class: 'primary--text blue lighten-5' }
            ],
            headersColumnGetData:{
                'SHIP. DATE': 'tglKirim',
                'TOTAL DELIV. NOTE': 'JumlahSJ',
                'GOODS(IDR)': 'IDRBarang',
                'SHIPPING COST(IDR)': 'IDROngkir',
                'COST RATIO(%)': 'persen',
                'MIN COST RATIO(%)': 'Minpersen',
                'MAX COST RATIO(%)': 'Maxpersen',
                'WEIGHT RATIO(%)': 'persenWgt',
                'MIN WEIGHT RATIO(%)': 'MinpersenWgt',
                'MAX WEIGHT RATIO(%)': 'MaxpersenWgt'
            },
            searchItem: '',
            loadingDatatable: false,
            sumDelivNote: '',
            sumTotalGoods: '',
            sumShippingCost: '',
            sumCostRatio: '',
            sumCostWeightRatio: '',
            itemDetailLists: [],
            itemKepuhDetailLists: [],
            headersDetails: [
                { text: 'TR ID', value: 'tr_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DESTINATION', value: 'destination', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DESC.', value: 'exp_descr', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DRIVER', value: 'driver_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'VEHICLE NO.', value: 'vehicle_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DELIV. NOTE', value: 'JumlahSJ', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'GOODS(IDR)', value: 'IDRBarang', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DELIV. COST(IDR)', value: 'IDROngkir', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'COST RATIO(%)', value: 'persen', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WEIGHT RATIO(%)', value: 'persenWgt', align: 'right', class: 'primary--text blue lighten-5' }
            ],
            headersExternalColumn:{
                'TR ID': 'tr_id',
                'DESTINATION': 'destination',
                'DESC.': 'exp_descr',
                'DRIVER': 'driver_id',
                'VEHICLE NO.': 'vehicle_id',
                'DELIV. NOTE': 'JumlahSJ',
                'GOODS(IDR)': 'IDRBarang',
                'DELIV. COST(IDR)': 'IDROngkir',
                'COST RATIO(%)': 'persen',
                'WEIGHT RATIO(%)': 'persenWgt',
            },
            headersInternalDetails: [
                { text: 'TR ID', value: 'tr_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DESTINATION', value: 'destination', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DESC.', value: 'exp_descr', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DRIVER', value: 'driver_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'VEHICLE NO.', value: 'vehicle_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DELIV. NOTE', value: 'JumlahSJ', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'GOODS(IDR)', value: 'IDRBarang', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DELIV. COST(IDR)', value: 'IDROngkir', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'COST RATIO(%)', value: 'persen', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WEIGHT RATIO(%)', value: 'persenWgt', align: 'right', class: 'primary--text blue lighten-5' }
            ],
            headersInternalColumn:{
                'TR ID': 'tr_id',
                'DESTINATION': 'destination',
                'DESC.': 'exp_descr',
                'DRIVER': 'driver_id',
                'VEHICLE NO.': 'vehicle_id',
                'DELIV. NOTE': 'JumlahSJ',
                'GOODS(IDR)': 'IDRBarang',
                'DELIV. COST(IDR)': 'IDROngkir',
                'COST RATIO(%)': 'persen',
                'WEIGHT RATIO(%)': 'persenWgt',
            },
            loadingDatatableDetail: false,
            isDetailDialogVisible: false,
            isDetailKepuhDialogVisible: false,
            entityPrint: '',
            millPrint: '',
            datePrint: '',
        }
    },
    async mounted(){
        this.getEnvConf()
        this.initialize()
        // this.populateExpedisi()
        document.querySelector(".cardResult").style.display = "none"

    },
    methods:{
        async getEnvConf() {
            try {
                const respData = await backendApi.fetchCore(
                    `/api/get_env_var_conf?entity_id=KMB&appl_id=WEBKMB&var_id=KMBCWR&groupid=${this.$store.state.kmb.group_id}`, null, false, false, false
                );

                console.log(respData.data.data);

                if (respData.data.data) {
                    this.isMillDisabled = true; 
                    this.millModel = respData.data.data; 
                } else {
                    this.isMillDisabled = false; 
                }
            } catch (error) {
                console.error('Error fetching environment configuration:', error);
                this.isMillDisabled = false; 
            }
        },
        getCellPropsMinMax(data) {
            if (parseFloat(data.item.persen) > 2) {
                return { class: 'tr_choice_error' };
            }
            return {};
        },
        close() {
            this.isDetailDialogVisible = false; // Menyembunyikan dialog detail
        },
        async initialize(){
            this.$store.dispatch('setOverlay', true)

            this.dateStartModel = this.currentDate()
            this.dateEndModel = this.currentDate()

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/CostRatioBaru`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.millLists = res.data.mill
                this.$store.dispatch('setOverlay', false)
            })

            await axios.post(
                `${process.env.VUE_APP_URL}/api/kmb/CostRatioBaru/populateExpedisi`,
                {
                    entity: this.entityModel,
                    mill_id: this.millModel
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    }
                }
            )
            .then(res => {
                this.expLists = res.data.result;
                this.$store.dispatch('setOverlay', false);
            });
        },

        async getExpedisi(){
            await axios.post(
                `${process.env.VUE_APP_URL}/api/kmb/CostRatioBaru/populateExpedisi`,
                {
                    entity: this.entityModel,
                    mill_id: this.millModel
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    }
                }
            )
            .then(res => {
                this.expLists = res.data.result;
                this.$store.dispatch('setOverlay', false);
            });
        },

        async getData(){

            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Ship From',
                        icon: 'error',
                    })
                return false
            }

            document.querySelector(".cardResult").style.display = "block";
            this.itemLists = []
            this.sumDelivNote = '',
            this.sumCostRatio = '',
            this.sumCostWeightRatio = '',
            this.sumShippingCost = '',
            this.sumTotalGoods = '',
            this.loadingDatatable = true
            this.isDetailDialogVisible= false,
            this.$store.dispatch('setOverlay', true)

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/CostRatioBaru/getData`, { 

                    entity: this.entityModel ? this.entityModel : '',
                    mill_id: this.millModel ? this.millModel : '',
                    dateFrom: this.dateStartFormatted,
                    dateTo: this.dateEndFormatted,
                    expedisi: this.expModel ? this.expModel : '',

                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                if(res.data.result.length != 0){
                    document.querySelector(".cardResult").style.display = "block";
                    this.itemLists = res.data.result
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    this.sumDelivNote = res.data.summary.totalSJ
                    this.sumTotalGoods = res.data.summary.totalIDRbarang
                    this.sumShippingCost = res.data.summary.totalIDROngkir
                    this.sumCostRatio = res.data.summary.costRatio
                    this.sumCostWeightRatio = res.data.summary.costWeightRatio
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'error',
                    })
                }
            })

        },

        async showItemDetails(id) {
            // $('#detailModal').modal('show');
            this.$store.dispatch('setOverlay', true)
            this.itemDetailLists = [];
            this.itemKepuhDetailLists = [];
            this.loadingDatatableDetail = true;

            this.newEntity = id.entity
            this.newmill_id= id.mill_id,
            this.newdate= id.tglKirim,
            this.newexpedisi= this.expModel ? this.expModel : '',

            console.log(this.newEntity, this.newmill_id, this.newdate, this.newexpedisi)

            await axios.post(
                `${process.env.VUE_APP_URL}/api/kmb/CostRatioBaru/getDataDetails`,
                {
                    entity: id.entity,
                    mill_id: id.mill_id,
                    date: id.tglKirim,
                    expedisi: this.expModel ? this.expModel : '',
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`,
                    },
                }
            )
            .then(res => {
                // this.loadingDatatableDetail = false;
                this.itemDetailLists = res.data.result;
                console.log("external", this.itemDetailLists)
                // this.$store.dispatch('setOverlay', false)
                // this.isDetailDialogVisible = true;
            })

            await axios.post(
                `${process.env.VUE_APP_URL}/api/kmb/CostRatioBaru/getDataDetailsKepuhExp`,
                {
                    entity: id.entity,
                    mill_id: id.mill_id,
                    date: id.tglKirim,
                    // expedisi: '141',
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`,
                    },
                }
            )
            .then(res => {
                // this.loadingDatatableDetail = false;
                this.itemKepuhDetailLists = res.data.result;
                console.log("internal", this.itemKepuhDetailLists)
                 
                // this.$store.dispatch('setOverlay', false)
                // this.isDetailDialogVisible = true;
            })

            this.entityPrint = id.entity
            console.log(this.entityPrint)
            this.millPrint = id.mill_id
            console.log(this.millPrint)
            this.datePrint = id.tglKirim
            console.log(this.datePrint)
            this.loadingDatatableDetail = false;
            this.$store.dispatch('setOverlay', false)
            this.isDetailDialogVisible = true;
        },

        async populateMills(id){

            if(id){

                this.$store.dispatch('setOverlay', true)

                await axios.post(`${process.env.VUE_APP_URL}/api/kmb/CostRatioBaru/populateMills`, { 
                        entity: id
                    },
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    }
                )
                .then(res => {

                    this.$store.dispatch('setOverlay', false)

                    this.millLists = res.data.result

                })

            } else {
                this.millLists = []
            }

        },
        
        async exportExcelGetData() {
            document.querySelector(".cardResult").style.display = "block";
            this.loadingDatatable = true;
            this.$store.dispatch('setOverlay', true);

            this.dateStartFormatted = this.dateStartModel 
                ? new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g, "")
                : '';
            this.dateEndFormatted = this.dateEndModel
                ? new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g, "")
                : '';

            try {
                const res = await axios.post(`${process.env.VUE_APP_URL}/api/kmb/CostRatioBaru/getData`, {
                    entity: this.entityModel || '',
                    mill_id: this.millModel || '',
                    dateFrom: this.dateStartFormatted,
                    dateTo: this.dateEndFormatted,
                    expedisi: this.expModel || '',
                }, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    }
                });

                if (res.data.result.length > 0) {
                    this.itemLists = res.data.result;
                    console.log("Export Data:", this.itemLists);
                    return this.itemLists; // Pastikan data dikembalikan
                } else {
                    Swal.fire({
                        text: 'Data not found!',
                        icon: 'error',
                    });
                    return []; // Jika tidak ada data, kembalikan array kosong agar `download-excel` tetap bekerja
                }
            } catch (error) {
                console.error("Error fetching export data:", error);
                return []; // Kembalikan array kosong jika ada error
            } finally {
                this.$store.dispatch('setOverlay', false);
                this.loadingDatatable = false;
            }
        },

        async exportExcelExternal() {
            this.$store.dispatch('setOverlay', true);
            this.loadingDatatableDetail = true;

            try {
                const res = await axios.post(
                    `${process.env.VUE_APP_URL}/api/kmb/CostRatioBaru/getDataDetails`,
                    {
                        entity: this.entityPrint,
                        mill_id: this.millPrint,
                        date: this.datePrint,
                        expedisi: this.expModel ? this.expModel : '',
                    },
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`,
                        },
                    }
                );

                this.itemDetailLists = res.data.result;
                console.log("external", this.itemDetailLists);

                return this.itemDetailLists; // ✅ Data harus dikembalikan agar `download-excel` bisa berfungsi
            } catch (error) {
                console.error("Error fetching export data:", error);
                return []; // Jika error, kembalikan array kosong agar tidak crash
            } finally {
                this.loadingDatatableDetail = false;
                this.$store.dispatch('setOverlay', false);
            }
        },

        async exportExcelInternal() {
            this.$store.dispatch('setOverlay', true);
            this.loadingDatatableDetail = true;

            try {
                const res = await axios.post(
                    `${process.env.VUE_APP_URL}/api/kmb/CostRatioBaru/getDataDetailsKepuhExp`,
                    {
                        entity: this.entityPrint,
                        mill_id: this.millPrint,
                        date: this.datePrint,
                    },
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`,
                        },
                    }
                );

                this.itemKepuhDetailLists = res.data.result;
                console.log("internal", this.itemKepuhDetailLists);

                return this.itemKepuhDetailLists; // ✅ Harus dikembalikan agar `download-excel` bisa bekerja
            } catch (error) {
                console.error("Error fetching export data:", error);
                return []; // Jika error, kembalikan array kosong agar tidak menyebabkan crash
            } finally {
                this.loadingDatatableDetail = false;
                this.$store.dispatch('setOverlay', false);
            }
        },

        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },

        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        }

    }
    
}
</script>

<style scoped>
    .tr_choice_error {
        background-color: #f8d7da; 
        color: #721c24; 
    }

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }
    
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .styled-table {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;
        font-size: 1.2em;
        font-family: Arial, sans-serif;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
        border: 1px solid #ddd;
    }

    .styled-table th,
    .styled-table td {
        padding: 12px 15px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    .styled-table th {
        background-color: #f2f2f2;
        color: #333;
        font-weight: bold;
    }

    .styled-table tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    .styled-table tr:hover {
        background-color: #f1f1f1;
    }

    .styled-table td {
        color: #555;
    }

</style>